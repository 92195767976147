import React from 'react'
import { Card, Table, Tabs } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import axios from 'axios'
import { size, tableConfig } from '../../styles-config'
import NumberFormat from 'react-number-format'

const { TabPane } = Tabs

const SenaraiHadiah = props => {
    const [hadiah, setHadiah] = React.useState([])

    React.useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_MEMBERSHIP_URL}/hadiah/list`)
            .then(response => {
                setHadiah(response.data)
                props.summary({
                    total: response.data.length,
                    hadiah: response.data.filter(
                        x => x.jenis_hadiah === 'hadiah'
                    ).length,
                    kupon: response.data.filter(x => x.jenis_hadiah === 'kupon')
                        .length
                })
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let columns = [
        { title: 'Nama Ganjaran', dataIndex: 'nama_ganjaran' },
        { title: 'Kuantiti', dataIndex: 'kuantiti' },
        {
            title: 'Mata Minimum',
            dataIndex: 'mata',
            className: 'text-center',
            render: text => (
                <NumberFormat
                    value={text}
                    thousandSeparator={true}
                    displayType={'text'}
                />
            )
        },
        { title: 'Jenis Ganjaran', dataIndex: 'jenis_hadiah' },
        {
            title: 'Tindakan',
            className: 'text-center',
            render: (text, record) => {
                return (
                    <span onClick={() => props.detailHadiah(text)}>
                        <SearchOutlined />
                    </span>
                )
            }
        }
    ]
    return (
        <Card title="Senarai Hadiah" size={size}>
            <Tabs>
                <TabPane tab="Semua Ganjaran" key="all">
                    <Table
                        {...tableConfig}
                        columns={columns}
                        size={size}
                        bordered
                        dataSource={hadiah}
                    />
                </TabPane>
                <TabPane tab="Hadiah" key="hadiah">
                    <Table
                        {...tableConfig}
                        columns={columns}
                        size={size}
                        bordered
                        dataSource={hadiah.filter(
                            x => x.jenis_hadiah === 'hadiah'
                        )}
                    />
                </TabPane>
                <TabPane tab="Kupon" key="kupon">
                    <Table
                        {...tableConfig}
                        columns={columns}
                        size={size}
                        bordered
                        dataSource={hadiah.filter(
                            x => x.jenis_hadiah === 'kupon'
                        )}
                    />
                </TabPane>
            </Tabs>
        </Card>
    )
}
export default SenaraiHadiah
