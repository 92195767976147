import React from 'react'
import { Row, Col, Breadcrumb, Card} from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { size } from '../../styles-config.js'

import SmsStatistic from './sms/Statistic'
import SmsSender from './sms/Sender'
import SmsTemplate from './sms/Template'
import SmsAutomate from './sms/Automate'

const tabList = [
    { 
        key: 'tab1',
        tab: 'SMS Automate'
    },
    { 
        key: 'tab2',
        tab: 'SMS Template'
    }
]

const contentList = {
    tab1: <SmsAutomate />,
    tab2: <SmsTemplate />   
}

const PageBreadcrumb = () => {
    return (
        <Breadcrumb style={{ marginBottom: 14 }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to="/pemasaran">Pemasaran</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>SMS</Breadcrumb.Item>
        </Breadcrumb>
    )
}

const Pemasaran = (props) => {
    const [ currentTab, setCurrentTab] = React.useState('tab1')
    return (
        <div>
            <PageBreadcrumb />
            <Row gutter={[12, 12]}>
                <Col span={14}>
                    <Row gutter={[12,12]}>
                    <Col span={24}><SmsStatistic {...props} domain={props.domain} /></Col>
                    <Col span={24}>
                        <Card
                            title="Tetapan SMS"
                            tabList={tabList}
                            size={size}
                            onTabChange={key => setCurrentTab(key)}
                        >
                        { contentList[currentTab]}
                        </Card>
                    </Col>
                    </Row>
                    
                </Col>
                <Col span={10}>
                    <SmsSender {...props} domain_list={props.domain_list} />
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        domain: state.globalReducer.auth.domain.split(',')[0],
        domain_list: state.globalReducer.auth.domain.split(',')
    }
}
export default connect(mapStateToProps)(Pemasaran)