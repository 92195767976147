exports.size = "small"

//Drawer
exports.drawerWidth = "40%"

//Form Layout
exports.formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
}
exports.tailLayout = {
    wrapperCol: { offset: 8, span: 16 }
}

exports.tableConfig = { 
    pagination : {
        pageSizeOptions : ['10','15','20','30','50','100'], 
        showSizeChanger : true,
        defaultPageSize: 15
    }
}