import React from 'react'
import { Breadcrumb, Card, Row, Col, Statistic, Skeleton } from 'antd'
import { Link } from 'react-router-dom'
import { RemoveDash } from '../../common'
import axios from 'axios'

import Membership from './membership'

const PageBreadcrumb = props => {
    return (
        <Breadcrumb style={{ marginBottom: 14 }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to="/rekod">Rekod</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to="/rekod-cawangan">Cawangan</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <span style={{ textTransform: 'capitalize' }}>
                    {RemoveDash(props.cawangan)}
                </span>
            </Breadcrumb.Item>
        </Breadcrumb>
    )
}

const PelangganSummary = props => {
    return (
        <Card size="small" title="Statistik Pelanggan">
            <Row>
                <Col span={24}>
                { (props.statistic === null) ? <Skeleton active /> :  <Statistic
                        title="Jumlah Pelanggan"
                        valueStyle={{ textAlign: 'center' }}
                        value={props.statistic}
                    />}
                    
                </Col>
            </Row>
        </Card>
    )
}

const CarianPelanggan = () => {
    return (
        <Card size="small" title="Carian Pelanggan">
            ...
        </Card>
    )
}

const Rekod = props => {
    const [dataSource, setDataSource] = React.useState(null)
    const [dataState, setDataState] = React.useState(null)
    let cawangan = props.match.params.cawangan

    React.useEffect(() => {
        setDataSource(null)
        setDataState(null)
        axios
            .get(
                `${process.env.REACT_APP_MEMBERSHIP_URL}/domain-daftar/${cawangan}`
            )
            .then(response => {
                setDataSource(response.data)
                setDataState(response.data.length)
            })
    }, [cawangan])

    const PelangganTable = props => {
        return (
            <Card size="small" title="Senarai Pelanggan">
                <Membership datasource={dataSource} />
            </Card>
        )
    }

    return (
        <div>
            <PageBreadcrumb cawangan={cawangan} />
            <Row gutter={[12, 12]}>
                <Col span={18}>
                    <PelangganTable />
                </Col>
                <Col span={6}>
                    <Row gutter={[12, 12]}>
                        <Col span={24}>
                            <PelangganSummary statistic={dataState} />
                        </Col>
                        <Col span={24}>
                            <CarianPelanggan />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default Rekod
