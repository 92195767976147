import React from 'react'
import {Switch, Route} from 'react-router-dom'
import { connect } from 'react-redux'

import Dashboard from './pages/dashboard'
import Rekod from './pages/rekod'
import Pemasaran from './pages/pemasaran'
import Hadiah from './pages/hadiah'

const Routes = (props) => {
    props.register_auth(props.auth.tokenParsed)
    return (
        <Switch>
            <Route path='/' exact component={Dashboard} />
            <Route path='/rekod/:cawangan?' component={Rekod} />
            <Route path='/pemasaran/:jenis?' component={Pemasaran} />
            <Route path='/hadiah' component={Hadiah} />
        </Switch>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        menu_cawangan: () => {
            dispatch({
                type: "SENARAI_CAWANGAN",
                payloads: 'bg list cawangan'
            })
        },
        register_auth: auth => {
            dispatch({
                type: "REGISTER_AUTH",
                payloads: auth
            })
        }
    }
}

export default connect(null, mapDispatchToProps)(Routes);