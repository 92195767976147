import React from 'react'
import { Card, Statistic, Row, Col } from 'antd'
import { size } from '../../styles-config'
import NumberFormat from 'react-number-format'

const SummaryHadiah = props => {
    return (
        <Row gutter={[12,12]}>
            <Col span={24}>
                <Statistic
                    title="Kuantiti Ganjaran"
                    valueStyle={{ textAlign: 'right' }}
                    value={props.summary !== null ? props.summary.total : 0}
                />
            </Col>
            <Col span={12}>
                <Statistic
                    title="Hadiah"
                    valueStyle={{ textAlign: 'right' }}
                    value={props.summary !== null ? props.summary.hadiah : 0}
                />
            </Col>
            <Col span={12}>
                <Statistic
                    title="Kupon"
                    valueStyle={{ textAlign: 'right' }}
                    value={props.summary !== null ? props.summary.kupon : 0}
                />
            </Col>
        </Row>
    )
}

const HadiahDetail = props => {
    let detail = props.detail
    return (
        <Card title="Maklumat Hadiah" size={size}>
            {detail !== null ? (
                <>
                    <img
                        src="https://upload.wikimedia.org/wikipedia/commons/8/84/Gift_Flat_Icon_Vector.svg"
                        alt="hadiah"
                    />
                    <table className="table">
                        <tbody>
                            <tr>
                                <th>Nama Ganjaran</th>
                                <td>{detail.nama_ganjaran}</td>
                            </tr>
                            <tr>
                                <th>Mata Kumpul</th>
                                <td>
                                    <NumberFormat
                                        value={detail.mata}
                                        thousandSeparator={true}
                                        displayType={'text'}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>Kuantiti</th>
                                <td>{detail.kuantiti}</td>
                            </tr>
                        </tbody>
                    </table>
                </>
            ) : (
                <SummaryHadiah {...props} />
            )}
        </Card>
    )
}

export default HadiahDetail
