import React from 'react'
import { ColumnChart } from '@opd/g2plot-react'
import { ColumnConfig } from '@antv/g2plot'
import axios from 'axios'
import { RemoveDash } from '../common'

const DaftarCawanganChart = (props) => {
    const [data, setData] = React.useState([])

    React.useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_MEMBERSHIP_URL}/domain-summary/${props.domain}`
            )
            .then(response => {
                let clean = []
                response.data.map((v,k)=>{
                    clean.push({
                        domain_daftar: RemoveDash(v.domain_daftar, false).toUpperCase(),
                        total: parseInt(v.total)
                    })
                    return true
                })
                setData(clean)
            })
    }, [props.domain])

    const config: ColumnConfig = {
        title: {
            visible: true,
            text: 'Statistic daftar pelanggan setiap cawangan'
        },
        forceFit: true,
        data,
        padding: 'auto',
        xField: 'domain_daftar',
        yField: 'total',
        meta: {
            total: {
                alias: 'Jumlah pelanggan berdaftar'
            },
            domain_daftar: {
                alias: 'Cawangan Ariffin'
            }
        }
    }

    const handleChartMount = React.useCallback(chart => {
        
    }, [])

    return <ColumnChart {...config} onMount={handleChartMount} />
}

export default DaftarCawanganChart
