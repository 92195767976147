import React from 'react'
import { Table, Drawer, Skeleton } from 'antd'
import { AuditOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format'
import CollectAndConsume from './CollectAndConsume'

const LogDrawer = props => {
    let showDrawer = props.showDrawer
    return (
        <Drawer
            title="Rekod transaksi pelanggan ini"
            placement="right"
            closable={true}
            onClose={() => props.toggleDrawer()}
            width="45%"
            visible={showDrawer}
        >
            <CollectAndConsume personid={props.personid} />
        </Drawer>
    )
}


const Membership = props => {

    const [showDrawer, setShowDrawer] = React.useState(false)
    const [personId, setPersonId] = React.useState(false)

    const columns = [
        {
            title: 'Nama',
            dataIndex: 'full_name',
            key: 'person_id'
        },
        {
            title: 'No Telefon',
            dataIndex: 'contact_no'
        },
        {
            title: 'Membership No',
            dataIndex: 'card_no'
        },
        {
            title: 'Mata Terkumpul',
            dataIndex: 'kumpul',
            className: 'text-center',
            render: (text, record) => (
                <NumberFormat
                    value={record.kumpul - record.guna}
                    thousandSeparator={true}
                    displayType={'text'}
                />
            )
        },
        {
            title: 'Transaksi',
            className: 'text-center',
            render: (text, record) => {
                return (
                    <div onClick={() => openDrawer(record.person_id)}>
                        <AuditOutlined />
                    </div>
                )
            }
        }
    ]

    const openDrawer = person_id => {
        setPersonId(person_id)
        setShowDrawer(!showDrawer)
    }

    const toggleDrawer = () => {
        setShowDrawer(!showDrawer)
    }

    return (
        <div>
            {props.datasource === null ? (
                <Skeleton active />
            ) : (
                <Table
                    dataSource={props.datasource}
                    columns={columns}
                    bordered
                    size="small"
                    rowKey="person_id"
                    pagination={{ pageSize: 25 }}
                />
            )}
            <LogDrawer
                {...props}
                showDrawer={showDrawer}
                toggleDrawer={() => toggleDrawer()}
                personid={personId}
            />
        </div>
    )
}

export default Membership
