import React from 'react'
import { Table, Drawer, Button, Form, Radio, Input, Divider, Tabs } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import axios from 'axios'

import {
    size,
    drawerWidth,
    formItemLayout,
    tailLayout
} from '../../../styles-config.js'

const { TextArea } = Input
const { TabPane } = Tabs

const TemplateForm = () => {
    const [maxString, setMaxString] = React.useState(120)
    let form_data = {
        jenis_sms: 'umum'
    }

    const remaining_string = string => {
        let max = 120
        setMaxString(max - string.length)
    }

    return (
        <Form {...formItemLayout} layout="horizontal">
            <Form.Item
                label="Jenis SMS"
                name="jenis_sms"
                defaultValue={form_data.jenis_sms}
                value={form_data.jenis_sms}
            >
                <Radio.Group>
                    <Radio value={'individu'}>Individu</Radio>
                    <Radio value={'umum'}>Umum</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item label="Nama Template" name="nama_template">
                <Input />
            </Form.Item>

            <Form.Item
                label="Kandungan Mesej"
                onChange={text => {
                    remaining_string(text.target.value)
                }}
            >
                <TextArea rows="4"></TextArea>
                <div>
                    <i>{maxString} Baki huruf</i>
                </div>
            </Form.Item>
            <Form.Item {...tailLayout} style={{ textAlign: 'right' }}>
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: 4 }}
                >
                    Simpan
                </Button>
                <Button htmlType="button">Reset</Button>
            </Form.Item>
        </Form>
    )
}

const TemplatePreview = props => {
    let list_ref = props.template
    let columns = [
        {
            title: 'Format',
            dataIndex: 'key',
            render: text => <span>*{text}*</span>
        },
        { title: 'Makluman', dataIndex: 'value' }
    ]
    return (
        <Tabs>
            <TabPane tab="Rujukan" key="1">
                <Table
                    dataSource={list_ref}
                    size={size}
                    bordered
                    columns={columns}
                />
            </TabPane>
            <TabPane tab="Contoh Pengunaan" key="2">
                <div>
                    <h3>Contoh</h3>
                    <p>
                        Salam <i>*nama*</i>, terima kasih diatas pembelian
                        barangan kemas dikedai kami.
                    </p>

                    <h3>Hasil</h3>
                    <p>
                        Salam Ali Mustar, terima kasih diatas pembelian barangan
                        kemas dikedai kami.
                    </p>
                </div>
            </TabPane>
        </Tabs>
    )
}

const SmsAutomate = props => {
    const [showDrawer, setShowDrawer] = React.useState(false)
    const [smsTemplate, setSmsTemplate] = React.useState([])
    const [automate, setAutomate] = React.useState([])

    React.useEffect(() => {
        // axios
        //     .get('http://localhost:5555/templating')
        //     .then(response => setSmsTemplate(response.data))

        axios
            .get(
                `${process.env.REACT_APP_MEMBERSHIP_URL}/pemasaran/sms/template-list/automate`
            )
            .then(response => {
                let new_ds = []
                response.data.map((v, k) => {
                    let ds_a = v
                    ds_a['key'] = v.id
                    new_ds.push(ds_a)
                    return true
                })
                setAutomate(new_ds)
            })
    }, [props])

    let columns = [
        { title: 'Jenis', dataIndex: 'type' },
        { title: 'Nama tetapan', dataIndex: 'subject' },
        { title: 'Mesej', dataIndex: 'body' }
    ]

    return (
        <div>
            <div style={{ textAlign: 'right' }} className="row-mb">
                <Button
                    type="primary"
                    onClick={() => setShowDrawer(!showDrawer)}
                >
                    <PlusOutlined />
                    Automate Baru
                </Button>
            </div>
            <Table
                columns={columns}
                bordered
                size={size}
                dataSource={automate}
            />
            <Drawer
                title="Template Automation"
                placement="right"
                visible={showDrawer}
                closeable="true"
                onClose={() => setShowDrawer(!showDrawer)}
                width={drawerWidth}
            >
                <TemplatePreview template={smsTemplate} />
                <Divider />
                <TemplateForm />
            </Drawer>
        </div>
    )
}

export default SmsAutomate
