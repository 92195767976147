exports.RemoveDash = (string, capitalize=true) => {
    let clean = string.replace(/-/g,' ')
    if(capitalize){
        clean = clean.split(' ').map((v) => v.charAt(0).toUpperCase() + v.slice(1) + ' ')
    }

    return clean
}

exports.ValidPhone = (string) => {
    let isValid = false
    if (string.length > 9 )
        isValid = true
    return isValid
}

// export { RemoveDash }