import React from 'react'
import { Card, Form, Radio, Input, Select, Button } from 'antd'
import { RemoveDash } from '../../../common.js'

const { TextArea } = Input
const { Option } = Select

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
}

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 }
}

const HantarSms = values => {
    console.log(values)
    return true
}

const SelectCawangan = props => {
    let cawangan = {
        cawangan: '0'
    }
    let options = []
    props.values.map((v,k)=> options.push(<Option key = {k+1} value={v} style={{ textTransform: 'capitalize'}}>{ RemoveDash(v)}</Option>))
    return (
        <Form.Item label="Cawangan" name="cawangan">
            <Select defaultValue={ cawangan.cawangan } onChange={ text => props.selected(text) }>
                <Option key='0' value={'0'}>Semua Cawangan</Option>
                {options}
            </Select>
        </Form.Item>
    )
}

const SmsSender = props => {
    const form_data = {
        kepada: "kumpulan",
        cawangan: "0",
        mesej: ""
    }
    const submitForm = values => {
        HantarSms(form_data)
    }
    return (
        <Card title="Maklumat SMS untuk dihantar" size="small">
            <Form {...formItemLayout} layout="horizontal" onFinish={submitForm}>
                <Form.Item label="Kepada" >
                    <Radio.Group defaultValue={form_data.kepada} onChange={ text => form_data.kepada = text.target.value}>
                        <Radio.Button value={"kumpulan"}>Kumpulan</Radio.Button>
                        <Radio.Button value={"bebas"}>Bebas</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <SelectCawangan values={props.domain_list} selected={ text => form_data.cawangan = text} />

                <Form.Item label="Mesej" name="mesej" onChange={ text => form_data.mesej = text.target.value}>
                    <TextArea rows={5} />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <div>Total Sms To Send : 0</div>
                    <div>Balance After Send : 0</div>
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Send SMS
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    )
}
export default SmsSender
