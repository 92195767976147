const membership_state = {
    bil_person: 0
}

const membershipReducer = (state = membership_state, action = {}) => {
    if(action.type === 'CURRENT_STATS'){
        return {
            ...state,
            bil_person: action.payloads
        }
    }
    return state
}

export default membershipReducer
