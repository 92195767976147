import React from 'react'
import { Layout, Menu } from 'antd'
import {
    UserOutlined,
    DashboardOutlined,
    ContainerOutlined,
    PartitionOutlined,
    GiftOutlined
} from '@ant-design/icons'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { RemoveDash } from '../common'

const { Sider } = Layout
const { SubMenu } = Menu

const AppSider = props => {
    const [ menuOpen, setMenuOpen] = React.useState(props.current_submenu)
    const change_route = e => {
        props.handleChangeRoute(e.key)
    }

    const onOpenChange =(openKeys) => {
        if(typeof openKeys === 'object'){
            if(openKeys.length > 1){
                openKeys.shift()
            }
            setMenuOpen(openKeys)
            props.handleOpenSub(openKeys)
        }
    }

    const listCawangan = () => {
        let items = []
        props.domain.map((v, k) => {
            items.push(
                <Menu.Item key={k + 1}>
                    <Link
                        to={`/rekod/${v}`}
                        style={{ textTransform: 'capitalize' }}
                    >
                        {RemoveDash(v)}
                    </Link>
                </Menu.Item>
            )
            return true
        })
        return <Menu.ItemGroup title="Cawangan Daftar">{items}</Menu.ItemGroup>
    }

    return (
        <>
            <Sider
                trigger={null}
                breakpoint="sm"
                collapsible
                collapsedWidth="0"
                collapsed={props.collapsed_sider}
            >
                <div className="logo" />
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={[props.current_route]}
                    onClick={change_route}
                    openKeys={menuOpen}
                    onOpenChange={ onOpenChange}
                >
                    <Menu.Item key="dashboard">
                        <Link to="/">
                            <DashboardOutlined />
                            <span>Dashboard</span>
                        </Link>
                    </Menu.Item>
                    <SubMenu
                        key="rekod"
                        title={
                            <>
                                <ContainerOutlined />
                                <span>Pelanggan</span>
                            </>
                        }
                    >
                        {listCawangan()}
                    </SubMenu>
                    <Menu.Item key="member-group">
                        <Link to='/kumpulan' ><PartitionOutlined /> Kumpulan Pelanggan</Link>
                    </Menu.Item>

                    <Menu.Item key="hadiah">
                        <Link to="/hadiah">
                            <GiftOutlined />
                            Ganjaran/Hadiah
                        </Link>
                    </Menu.Item>
                    <SubMenu
                        key="marketing"
                        title={
                            <span>
                                <UserOutlined />
                                <span>Marketing</span>
                            </span>
                        }
                    >
                        <Menu.Item key="pemasaran-sms">
                            <Link to="/pemasaran/sms">SMS</Link>
                        </Menu.Item>
                        <Menu.Item key="pemasaran-email">
                            <Link to="/pemasaran/email">Email</Link>
                        </Menu.Item>
                        <Menu.Item key="pemasaran-facebook">
                            <Link to="/pemasaran/facebook">Facebook</Link>
                        </Menu.Item>
                    </SubMenu>
                </Menu>
            </Sider>
        </>
    )
}

const mapStateToProps = state => {
    return {
        collapsed_sider: state.globalReducer.menu_collapsed,
        current_route: state.globalReducer.current_menu,
        current_submenu: state.globalReducer.current_sub
    }
}

const mapDispatchToProps = dispatch => {
    return {
        handleChangeRoute: key => dispatch({ type: 'CURRENT_ROUTE', route_key: key }),
        handleOpenSub: key => dispatch({ type: 'CURRENT_SUB', sub_key: key})
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AppSider)
