import React from 'react'
import Keycloak from 'keycloak-js'
import { Layout } from 'antd'
import { connect } from 'react-redux'

import Header from './layout/header'
import Sider from './layout/sider.js'
import Footer from './layout/footer'
import './App.css'

import Routes from './routes'

const { Content } = Layout

const MainApps = (props) => {
    const [auth, setAuth] = React.useState({})

    React.useEffect(() => {
        const keycloak = Keycloak({
            realm: process.env.REACT_APP_SSO_REALM,
            'url': 'https://sso.pengurusanemas.my/auth',
            'ssl-required': 'external',
            clientId: 'spe-crm',
            'public-client': true,
            'confidential-port': 0,
        })
        keycloak
            .init({
                onLoad: 'login-required',
                promiseType: 'native',
                checkLoginIframe: false
            })
            .then(authenticated => {
                setAuth({
                  keycloak: keycloak,
                  tokenParsed: keycloak.idTokenParsed,
                  authenticated: authenticated,
                  apps: keycloak.resourcesAccess,
                })
            })
    },[])

    if (auth.keycloak) {
        if (auth.authenticated && typeof auth.tokenParsed.domain !== 'undefined') {
            return (
                <Layout style={{ height: '100vh' }}>
                    <Sider domain={auth.tokenParsed.domain.split(',')} />
                    <Layout className="site-layout">
                        <Header auth={auth} />
                        <Content
                            className="site-layout-background"
                            style={{
                                margin: '14px',
                                marginTop: 80,
                                minHeight: 280,
                                overflow:'initial'
                            }}
                        >
                            <Routes auth={auth} />
                            <Footer />
                        </Content>
                        
                    </Layout>
                </Layout>
            )
        } else {
            return <div>unable to authenticate</div>
        }
    }
    return <div>initilizing authentication</div>
}


export default connect(null)(MainApps)
