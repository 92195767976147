import React from 'react'
import { LineChart } from '@opd/g2plot-react'
import { LineConfig } from '@antv/g2plot'
import axios from 'axios'
import moment from 'moment'

const DaftarHarianChart = (props) => {
    const [data,setData] = React.useState([])

    React.useEffect(()=>{
        axios.get(
            `${process.env.REACT_APP_MEMBERSHIP_URL}/registration-summary/${props.domain}`
        ).then(response => {
            let clean = []
            response.data.map((v,k)=>{
                clean.push({
                    tarikh: moment(v.tarikh_daftar).format("DD/MM/YYYY"),
                    total: parseInt(v.total)
                })
                return true
            })
            setData(clean)
        })
    },[props.domain])

    const config: LineConfig = {
        title: {
            visible: true,
            text: 'Statistik pendaftaran pelanggan harian'
        },
        forceFit: true,
        padding: 'auto',
        data,
        xField: 'tarikh',
        yField: 'total',
        smooth: true,
        point: {
            visible: true,
            size: 3
        },
        xAxis: {
            tickCount: 7,
            label: {
                autoHide: true,
                autoRotate: true,
                visible: true
            }

        }
    }

    const handleChartMount = React.useCallback(chart => {},[])

    return <LineChart {...config} onMount={handleChartMount} />
}

export default DaftarHarianChart