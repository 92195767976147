import React from 'react'
import { Card } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import axios from 'axios'

const SmsStatistic = props => {
    const [kredit, setKredit] = React.useState(false)

    React.useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_MEMBERSHIP_URL}/setup/${props.domain}`
            )
            .then(response => {
                let sms = response.data.config.sms
                sms.map((value, key) => {
                    let payloads = {
                        apiusername: value.username,
                        apipassword: value.password
                    }
                    RetrieveStatistic(payloads)
                    return true
                })
            })
    }, [props])

    const RetrieveStatistic = data => {
        axios
            .post(
                `${process.env.REACT_APP_SMS_GATEWAY}/onewaysms/check-balance`,
                data
            )
            .then(response => setKredit(response.data))
    }

    return (
        <Card title="Maklumat SMS" size="small">
            Baki SMS : {kredit === false ? <LoadingOutlined /> : kredit.baki}
        </Card>
    )
}

export default SmsStatistic
