import React from 'react'
import { Table } from 'antd'
import axios from 'axios'

const SmsTemplate = props => {
    const [template, setTemplate] = React.useState([])
    let columns = [
        {
            title: 'Jenis',
            dataIndex: 'type'
        },
        {
            title: 'Nama Template',
            dataIndex: 'subject'
        },
        {
            title: 'Mesej',
            dataIndex: 'body'
        }
    ]

    React.useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_MEMBERSHIP_URL}/pemasaran/sms/template-list/template`
            )
            .then(response => {
                let new_ds = []
                response.data.map((v, k) => {
                    let ds_a = v
                    ds_a['key'] = v.id
                    new_ds.push(ds_a)
                    return true
                })
                setTemplate(new_ds)
            })
    }, [])
    return (
        <Table columns={columns} bordered size="small" dataSource={template} />
    )
}

export default SmsTemplate
