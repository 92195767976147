import React from 'react'
import { Breadcrumb, Row, Col } from 'antd'

import SenaraiHadiah from './SenaraiHadiah'
import HadiahDetail from './Detail'

const PageBreadcrumb = props => {
    return (
        <Breadcrumb>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Hadiah</Breadcrumb.Item>
        </Breadcrumb>
    )
}

const Hadiah = props => {
    const [ detailHadiah, setDetailHadiah ] = React.useState(null)
    const [ summaryHadiah, setSummaryHadiah ] = React.useState(null)

    return (
        <div>
            <PageBreadcrumb />
            <Row gutter={[12, 12]}>
                <Col span={18}><SenaraiHadiah detailHadiah={(data) => setDetailHadiah(data)} summary={(data) => setSummaryHadiah(data)} /></Col>
                <Col span={6}><HadiahDetail detail={detailHadiah} summary={summaryHadiah} /></Col>
            </Row>
        </div>
    )
}

export default Hadiah