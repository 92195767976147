import React from 'react'
import { Card, Form, Input, Divider, Table } from 'antd'
import { size} from '../../styles-config'
import axios from 'axios'
import { RemoveDash} from '../../common'

const { Search } = Input

const RekodCarianPelanggan = props => {

    const [ person, setPerson] = React.useState([])

    const FindPerson = person => { 
        axios.get(
            `${process.env.REACT_APP_MEMBERSHIP_URL}/person/search/${person}`
        )
        .then(response => setPerson(response.data))
    }

    const FindResult = props => {
        let columns = [
            { title: 'Nama', dataIndex:'full_name'},
            { title: 'No Ic', dataIndex:'identification_no'},
            { title: 'No Telefon', dataIndex:'contact_no'},
            { title: 'Daftar', dataIndex:'domain_daftar', render: (text) => <span>{ RemoveDash(text)}</span>}
        ]
        return <Table columns={columns} bordered size={size} dataSource={person} />
    }

    return (
        <Card title="Carian Maklumat Pelanggan" size={size}>
            <Form layout="vertical">
                <Form.Item>
                    <Search placeholder="No Ic/ Telefon / Kad Ahli" enterButton onSearch={(e)=> FindPerson(e)}/>
                </Form.Item>
            </Form>
            <Divider />
            <FindResult />
        </Card>
    )
}

export default RekodCarianPelanggan