import React from 'react'
import axios from 'axios'
import { Table } from 'antd'
import { CheckCircleFilled, CloseCircleFilled} from '@ant-design/icons'
import moment from 'moment'
import { connect } from 'react-redux'
import { RemoveDash, ValidPhone } from '../../common'
import NumberFormat from 'react-number-format'

const PersonLogTable = props => {
    let items = []
    if (props.log !== false){
        props.log.map((v,k)=>{
        let data = v
        data['key'] = k
        items.push(data)
        return true
    })
    }
    

    let columns = [
        {
            title: 'Tarikh',
            dataIndex: 'tarikh',
            key: 'tarikh',
            render: (text) => moment(text).format("DD/MM/YYYY")
        },
        {
            title: 'Perkara',
            dataIndex: 'perkara',
            key: 'perkara'
        },
        {
            title: 'Cawangan',
            dataIndex: 'domain',
            key: 'domain',
            render: (text) => RemoveDash(text)
        },
        {
            title: 'Mata',
            dataIndex: 'mata_ganjaran',
            key: 'mata',
            className: 'text-center',
            render: (text) => <NumberFormat value={text} thousandSeparator={true} displayType={'text'} />
        }
    ]
    return (
        <Table bordered size="small" dataSource={items} columns={columns} style={{marginBottom: 12}} />
    )
}

const PersonCard = props => {
    let person = props.person_data
    return (
        <>
        { (person) ? 
            <table style={{width: '100%'}} className="table-bordered">
                <tbody>
                    <tr>
                        <th>No Pelanggan</th>
                        <td>{person.card_no}</td>
                        <td rowSpan="6" style={{verticalAlign: 'middle', textAlign: 'center'}}>
                        <h1>
                            <NumberFormat value={person.collected} thousandSeparator={true} displayType={'text'} />
                        </h1>
                        </td>
                    </tr>
                    <tr>
                        <th>Nama</th>
                        <td>{person.full_name}</td>
                    </tr>
                    <tr>
                        <th>Tarikh Daftar</th>
                        <td>{moment(person.tarikh_daftar).format('DD/MM/YYYY')}</td>
                    </tr>
                    <tr>
                        <th>No Telefon</th>
                        <td>{person.contact_no} - { (ValidPhone(person.contact_no) ? <CheckCircleFilled style={{color:'green'}} /> : <CloseCircleFilled style={{color:'red'}} />)}</td>
                    </tr>
                    <tr>
                        <th>Alamat</th>
                        <td>{person.alamat}</td>
                    </tr>
                    <tr>
                        <th>Kedai Daftar</th>
                        <td style={{textTransform: 'capatilize'}}>{  RemoveDash(person.domain_daftar) }</td>
                    </tr>
                </tbody>
            </table> :''}
        </>
    )
}

const CollectAndConsume = props => {
    const [collectConsume, setCollectConsume] = React.useState(false)
    const [collectionTrail, setColletionTrail] = React.useState(false)

    React.useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_MEMBERSHIP_URL}/person-and-point-2/${props.personid}`
            )
            .then(response => setCollectConsume(response.data))

        axios
            .get(
                `${process.env.REACT_APP_MEMBERSHIP_URL}/person/point-log-2/${props.personid}`
            )
            .then(response => setColletionTrail(response.data))
    }, [props.personid, props.domain])

    return (
        <div>
            <PersonCard person_data={collectConsume}  />
            <div style={{height: 12}}></div>
            <PersonLogTable log={collectionTrail} />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        domain: state.globalReducer.auth.domain.split(',')[0]
    }
}

export default connect(mapStateToProps)(CollectAndConsume)
