import React from 'react'
import { Row, Col, Card } from 'antd'
import { connect } from 'react-redux'
import { size } from '../../styles-config'

import DaftarCawanganChart from '../../charts/DaftarCawangan'
import DaftarHarianChart from '../../charts/DaftarHarian'

import CarianPelanggan from '../rekod/CarianPelanggan'

const Dashboard = (props) => {
    return(
        <>
        <Row gutter={12} className="row-mb">
            <Col span={16}>
                <Row gutter={[12, 12]}>
                    <Col span={24}>
                        <Card size={size}>
                        <DaftarCawanganChart domain={props.domain} />
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Card size={size}>
                        <DaftarHarianChart domain={props.domain} />
                        </Card>
                    </Col>
                </Row>
            </Col>

            <Col span={8}>
                <CarianPelanggan domain={props.domain} />
            </Col>
        </Row>
        </>
    )
}

const mapStateToProps = state => {
    let domain = state.globalReducer.auth.domain.split(',') 
    return {
        domain: domain[0]
    }
}

export default connect(mapStateToProps)(Dashboard)